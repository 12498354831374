<template>
  <div class="act-box">
    <div class="wrapper">
      <div class="navbar-custom">
        <ul id="ajaxlogin" class="list-unstyled topnav-menu float-right mb-0">
          <li class="dropdown notification-list">
            <a
              class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <!-- <img
                src="/assets/images/users/avatar.png"
                alt="user-image"
                class="rounded-circle"
              /> -->
              <span class="pro-user-name ml-1">
                ACT Test
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
              <!-- item-->
              <div class="dropdown-item noti-title">
                <h6 class="m-0">
                  Welcome !
                </h6>
              </div>
              <!-- item-->
              <a href="/exam/profile.php" class="dropdown-item notify-item">
                <i class="dripicons-user"></i>
                <span>My Account</span>
              </a>
              <!-- item-->
              <a href="/exam/index.php" class="dropdown-item notify-item">
                <i class="dripicons-pencil"></i>
                <span>Take ACT Test</span>
              </a>
              <!-- item-->
              <a href="/exam/history.php" class="dropdown-item notify-item">
                <i class="dripicons-pamphlet"></i>
                <span>Exam History</span>
              </a>
              <div class="dropdown-divider"></div>
              <!-- item-->
              <a href="/user/logout.php" class="dropdown-item notify-item">
                <i class="dripicons-exit"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
        <ul class="list-unstyled menu-left topnav-menu mb-0">
          <li>
            <a href="/" class="logo" title="home">
              <span class="logo-lg">
                <img src="/assets/images/logo-light.png" alt="" height="42" />
              </span>
              <span class="logo-sm">
                <img src="/assets/images/logo-sm.png" alt="" height="45" />
              </span>
            </a>
          </li>
          <li>
            <a class="button-menu-mobile navbar-toggle">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </li>
          <!--3md lg 2xl-->
        </ul>
      </div>
      <div class="left-side-menu">
        <div
          class="slimScrollDiv mm-active"
          style="position: relative; overflow: hidden; width: auto; height: 1129px;"
        >
          <div
            class="slimscroll-menu mm-show"
            style="overflow: hidden; width: auto; height: 1129px;"
          >
            <!--- Sidemenu -->
            <div id="sidebar-menu" class="mm-active">
              <ul class="metismenu mm-show" id="side-menu">
                <li class="mm-active">
                  <router-link
                    :to="{
                      name: 'ACTList'
                    }"
                    class="active"
                    ><i class="dripicons-pencil"></i>
                    <span> Take ACT Tests </span></router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'ACTHistory'
                    }"
                    ><i class="dripicons-pamphlet"></i>
                    <span> ACT Exam History </span></router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'ACTExplanations'
                    }"
                    ><i class="dripicons-document"></i>
                    <span> ACT Test Explanations </span></router-link
                  >
                </li>
              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
          <div
            class="slimScrollBar"
            style="background: rgb(158, 165, 171); width: 8px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 1129px;"
          ></div>
          <div
            class="slimScrollRail"
            style="width: 8px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"
          ></div>
        </div>
      </div>
      <div
        class="content-page"
        style="height: auto !important; min-height: 0px !important;"
      >
        <div class="container-fluid" style="height: auto !important;">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h1 class="page-title">Take ACT Tests Online</h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pl-0 pr-0">
              <div class="card-box pl-1 pr-1 pt-2 pb-2 mb-1">
                <div class="table-responsive pl-3 pr-3 pt-1 pb-3 mt-1">
                  <p>Please click the links below to take your ACT test.</p>
                  <table
                    class="table table-bordered table-striped table-hover text-center"
                  >
                    <tr class="text-light bg-primary">
                      <th>No</th>
                      <th>ACT Exam Name</th>
                      <th colspan="2">Test Mode</th>
                    </tr>
                    <tr v-for="i in 83" :key="i">
                      <td>1</td>
                      <td>December 2020 Form D03</td>
                      <td class="td-section">
                        <a
                          href="/exam/start.php?type=english&amp;examid=202012&amp;mode=0"
                          >English</a
                        >
                        <a
                          href="/exam/start.php?type=math&amp;examid=202012&amp;mode=0"
                          >Math</a
                        >
                        <a
                          href="/exam/start.php?type=reading&amp;examid=202012&amp;mode=0"
                          >Reading</a
                        >
                        <a
                          href="/exam/start.php?type=science&amp;examid=202012&amp;mode=0"
                          >Science</a
                        >
                      </td>
                      <td>
                        <a
                          href="/exam/start.php?type=english&amp;examid=202012&amp;mode=1"
                          >Composite</a
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "ACT - ACT Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>
